import { isBefore } from 'date-fns'
import { Medicao } from 'graphql/types.generated'
import moment from 'moment'
import { toDate } from 'util/date/formatDate'
import { formatNumber, parseNumber } from 'util/number'
import { isEmpty } from 'util/validation/Util'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { MedicaoAnteriorFormModel } from './MedicaoAnteriorForm'
import { MedicaoPeso } from './model-medicoes'

export const calculatePeso = (
  pesoAtual: string,
  ultimaMedicaoAnterior: MedicaoAnteriorFormModel,
  ultimaMedicaoSalva: Medicao,
  dataInicioAtendimento: Date
): MedicaoPeso => {
  const pesoNumber = parseNumber(pesoAtual)
  const hasPesoAtual = !isNaN(pesoNumber)
  const hasUltimaMedicaoAnterior = !isEmpty(ultimaMedicaoAnterior)
  const hasUltimaMedicaoSalva = !isEmpty(ultimaMedicaoSalva)

  if (hasPesoAtual) {
    return {
      dataMedicao: dataInicioAtendimento.getTime(),
      peso: formatNumber(pesoNumber),
    }
  }

  if (hasUltimaMedicaoAnterior) {
    const ultimaMedicaoAnteriorDate = toDate(ultimaMedicaoAnterior.data)
    if (
      !hasUltimaMedicaoSalva ||
      (hasUltimaMedicaoSalva && isBefore(ultimaMedicaoSalva.dataMedicao, ultimaMedicaoAnteriorDate))
    ) {
      return {
        dataMedicao: ultimaMedicaoAnteriorDate.getTime(),
        peso: formatNumber(ultimaMedicaoAnterior.peso),
      }
    }
  }

  if (hasUltimaMedicaoSalva) {
    return {
      dataMedicao: ultimaMedicaoSalva.dataMedicao,
      peso: formatNumber(ultimaMedicaoSalva.valorPeso),
    }
  }

  return null
}

const format = (data) => moment(data).format('DD/MM/YY')

export function reduceMedicoesIguais<T extends Pick<HistoricoMedicaoModel, 'dataMedicao'>>(
  medicoes: ReadonlyArray<T>,
  key: keyof T
): T[] {
  const mapMedicoesUnicas = medicoes?.reduce(
    (acc, curr) => (curr[key] ? acc.set(`${format(curr.dataMedicao)}|${curr[key]}`, curr) : acc),
    new Map<string, T>()
  )

  return Array.from(mapMedicoesUnicas.values())
}
