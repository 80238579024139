/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { catmatAcitretina, catmatIsotretinoina, catmatTalidomida, catmatTretinoina } from 'api/ImpressaoMedicamentos'
import { Button, Checkbox, HFlow, isEmpty, ModalFooter, Text, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { resolveName } from 'components/form/final-form/hooks/useField'
import theme from 'config/theme'
import { differenceInYears } from 'date-fns'
import { IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useServerTime } from 'hooks/useServerTime'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import {
  TermosOutrosMedicamentosImpressaoPrescricao,
  termosPrescricaoImpressao,
  TermosTalidomidaImpressaoPrescricao,
} from 'types/enums'
import { metaPath } from 'util/metaPath'

import { MedicamentoFormModel, MedicamentoSubtitle } from '../../model-prescricao'
import { PrescricaoMedicamentoPlanoModel } from '../../PrescricaoMedicamentoView'
import { convertMedicamentoToPrintDto } from '../../utils/converter-prescricao'
import { hasIsotretinoinaTretinoinaAcitretina } from '../../utils/utils-prescricao'
import { EncaminharPrescricaoMedicamentoItem } from '../components/EncaminharPrescricaoMedicamentoItem'
import TermosToPrintList from '../components/TermosToPrintList'
import { PrescricaoDigitalFormModel } from '../prescricao-digital/model-prescricaoDigital'
import { downloadMedicamentos, downloadReimpressaoMedicamentos } from './downloadMedicamentos'
import {
  ImpressaoMedicamentosInput,
  ImpressaoTermosDto,
  ReimpressaoReceitaMedicamentosDto,
} from './ImpressaoMedicamentosInput'

const path = metaPath<PrescricaoMedicamentoPlanoModel>()

export interface ImpressaoPrescricaoViewProps {
  medicamentos: MedicamentoFormModel[]
  prescricaoDigital: PrescricaoDigitalFormModel
  atendimentoId: ID
  municipioId: ID
  cidadaoId: ID
  cidadaoDataNascimento: LocalDate
  cidadaoSexo: SexoEnum
  isReimpressao: boolean
  cidadaoIdentidadeGenero: IdentidadeGeneroEnum
  onModalClose: () => void
}

export function ImpressaoPrescricaoView(props: ImpressaoPrescricaoViewProps) {
  const {
    medicamentos,
    prescricaoDigital,
    atendimentoId,
    municipioId,
    cidadaoId,
    cidadaoDataNascimento,
    cidadaoSexo,
    isReimpressao,
    cidadaoIdentidadeGenero,
    onModalClose,
  } = props
  const [medicamentosToPrint, setMedicamentosToPrint] = useState<MedicamentoFormModel[]>(medicamentos)
  const [medicamentosSelected, setMedicamentosSelected] = useState<MedicamentoFormModel[]>(medicamentos)
  const [isLoading, setIsLoading] = useState(false)

  const [subtitleTermos, setSubtitleTermos] = useState('')
  const [termosSelected, setTermosSelected] = useState<ImpressaoTermosDto[]>([])

  const [allChecked, setAllChecked] = useState(true)
  const { analytics } = useFirebase()
  const { getServerTimeNow } = useServerTime()
  const alert = useAlert()

  const termosToPrint = useRef<ImpressaoTermosDto[]>([])

  const idadeCidadao = differenceInYears(getServerTimeNow(), new Date(cidadaoDataNascimento))

  const talidomidas = useMemo(
    () =>
      medicamentosToPrint.filter((medicamento) => medicamento.principioAtivoCombo?.codigoCatMat === catmatTalidomida),
    [medicamentosToPrint]
  )

  const hasTalidomida = useMemo(() => !isEmpty(talidomidas), [talidomidas])

  const noSelections = isEmpty(medicamentosSelected) && isEmpty(termosSelected)

  const isAtendimento = !isEmpty(atendimentoId)

  const insertTermos = useCallback(() => {
    const termos: ImpressaoTermosDto[] = []
    const anexoV_A = termosPrescricaoImpressao[TermosTalidomidaImpressaoPrescricao.ANEXO_V_A]
    const anexoV_B = termosPrescricaoImpressao[TermosTalidomidaImpressaoPrescricao.ANEXO_V_B]

    const isCidadaoMaior55 = idadeCidadao >= 55
    const isCidadaoCis =
      cidadaoIdentidadeGenero === IdentidadeGeneroEnum.HOMEM_CIS ||
      cidadaoIdentidadeGenero === IdentidadeGeneroEnum.MULHER_CIS
    const isCidadaoIndeterminadoOrNotCis =
      cidadaoSexo === SexoEnum.INDETERMINADO || (cidadaoIdentidadeGenero && !isCidadaoCis)

    const hasAnexoVAOrXVI = isCidadaoIndeterminadoOrNotCis || cidadaoSexo === SexoEnum.MASCULINO || isCidadaoMaior55
    const hasAnexoVBOrXV = !isCidadaoMaior55 && (isCidadaoIndeterminadoOrNotCis || cidadaoSexo === SexoEnum.FEMININO)

    const filtroTalidomida = (termo: string) => {
      const isNotAnexoV = termo !== anexoV_A && termo !== anexoV_B
      const hasAnexoVA = termo === anexoV_A && hasAnexoVAOrXVI
      const hasAnexoVB = termo === anexoV_B && hasAnexoVBOrXV

      return isNotAnexoV || hasAnexoVA || hasAnexoVB
    }

    const addTermo = (termo: TermosOutrosMedicamentosImpressaoPrescricao, catmatList: string[]) => {
      const medicamento = medicamentosToPrint.find((medicamento) =>
        catmatList.includes(medicamento.principioAtivoCombo.codigoCatMat)
      )
      if (medicamento) {
        termos.push({
          termo: termosPrescricaoImpressao[termo],
          codigosCatmatRelacionados: medicamentosToPrint.map((med) => med.principioAtivoCombo.codigoCatMat),
          receitaId: medicamento.editingId,
        } as ImpressaoTermosDto)
      }
    }

    const verificarEAdicionarTermo = (catmatList: string[]) => {
      if (
        medicamentosToPrint.some((medicamento) => catmatList.includes(medicamento.principioAtivoCombo.codigoCatMat))
      ) {
        if (hasAnexoVBOrXV) {
          addTermo(TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XV, catmatList)
        }
        if (hasAnexoVAOrXVI) {
          addTermo(TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XVI, catmatList)
        }
      }
    }

    if (hasTalidomida) {
      talidomidas.forEach((talidomida) => {
        Object.keys(TermosTalidomidaImpressaoPrescricao)
          .filter((termo) => filtroTalidomida(termo))
          .forEach((key: TermosTalidomidaImpressaoPrescricao) =>
            termos.push({
              idMedicamentoReferencia: talidomida.id,
              receitaId: talidomida.editingId,
              termo: key,
              codigosCatmatRelacionados: [talidomida.principioAtivoCombo.codigoCatMat],
              principioAtivo: talidomida.principioAtivoCombo?.principioAtivo.nome,
              quantidade: talidomida.quantidade,
              duracaoTratamento: talidomida.duracao,
              unidadeMedidaDuracao: talidomida.escalaDuracao,
              dataInicioTratamento: talidomida.dataInicioTratamento,
            } as ImpressaoTermosDto)
          )
      })
    }

    verificarEAdicionarTermo(catmatAcitretina)
    verificarEAdicionarTermo(catmatTretinoina)
    verificarEAdicionarTermo(catmatIsotretinoina)

    setTermosSelected(termos)
    termosToPrint.current = termos
  }, [cidadaoIdentidadeGenero, cidadaoSexo, hasTalidomida, idadeCidadao, medicamentosToPrint, talidomidas])

  useEffect(() => {
    setMedicamentosToPrint(medicamentos)
  }, [medicamentos])

  useEffect(() => {
    const subtitleItems: string[] = []
    medicamentos.forEach((med) => {
      if (catmatAcitretina.includes(med.principioAtivoCombo?.codigoCatMat)) {
        subtitleItems.push(MedicamentoSubtitle.ACITRETINA)
      } else if (catmatIsotretinoina.includes(med.principioAtivoCombo?.codigoCatMat)) {
        subtitleItems.push(MedicamentoSubtitle.ISOTRETINOINA)
      } else if (catmatTretinoina.includes(med.principioAtivoCombo?.codigoCatMat)) {
        subtitleItems.push(MedicamentoSubtitle.TRETINOINA)
      }
    })
    setSubtitleTermos(subtitleItems.join(' / '))
    insertTermos()
  }, [medicamentos, insertTermos])

  const handleOnChangeMedicamentosSelected = (checked: boolean, medicamento: MedicamentoFormModel) => {
    if (checked) {
      setMedicamentosSelected([...medicamentosSelected, medicamento])
    } else {
      setMedicamentosSelected(medicamentosSelected.filter((med) => med.id !== medicamento.id))
      setAllChecked(false)
    }
  }

  const handleOnChangeTermosSelected = (checked: boolean, termoChecked: ImpressaoTermosDto) => {
    if (checked) {
      setTermosSelected([...termosSelected, termoChecked])
    } else {
      setTermosSelected(termosSelected.filter((termoSelected) => termoSelected.termo !== termoChecked.termo))
      setAllChecked(false)
    }
  }

  const handleOnChangeAllChecked = () => {
    const newState = !allChecked
    setAllChecked(newState)
    setMedicamentosSelected(newState ? medicamentos : [])
    setTermosSelected(newState ? termosToPrint.current : [])
  }

  const renderFieldArray = (renderFieldArrayProps: FieldArrayRenderProps<ID, any>) => {
    const {
      fields: { push, value },
    } = renderFieldArrayProps

    const findAlreadyPrintedElement = (id: ID) => value?.includes(id)

    const insertMedicamentosAndTermosImpressos = () => {
      setIsLoading(false)
      medicamentosSelected.forEach((medicamento) => {
        if (!findAlreadyPrintedElement(medicamento)) {
          push(medicamento.id)
        }
      })
      termosSelected.forEach((termo) => {
        if (!findAlreadyPrintedElement(termo.termo)) {
          push(termo.termo)
        }
      })
      onModalClose()
    }

    const handlePrintImpressao = () => {
      setIsLoading(true)

      const input: ImpressaoMedicamentosInput = {
        atendimentoId: atendimentoId,
        cidadaoDataNascimento: Date.parse(cidadaoDataNascimento),
        cidadaoSexo: cidadaoSexo,
        localidadeId: municipioId,
        isotretinoinaTretinoinaAcitretinaSub: subtitleTermos ?? null,
        medicamentos: medicamentosSelected.map((item) => convertMedicamentoToPrintDto(item)),
        termos: termosSelected,
      }

      downloadMedicamentos(input)
        .then(() => {
          insertMedicamentosAndTermosImpressos()
        })
        .catch(() => {
          alert('danger', `Erro ao imprimir prescrição de medicamentos.`)
          setIsLoading(false)
        })

      analytics.logEvent('click_imprimir_modal_impressao_prescricao')
    }

    const handlePrintReimpressao = () => {
      setIsLoading(true)

      const input: ReimpressaoReceitaMedicamentosDto = {
        contextoId: isAtendimento ? atendimentoId : cidadaoId,
        receitasMedicamento: medicamentosSelected.map((medicamento) => medicamento.editingId),
        termos: termosSelected,
        isotretinoinaTretinoinaAcitretinaSub: subtitleTermos ?? null,
        isAtendimento: isAtendimento,
      }

      downloadReimpressaoMedicamentos(input)
        .then(() => {
          insertMedicamentosAndTermosImpressos()
        })
        .catch(() => {
          alert('danger', `Erro ao reimprimir prescrição de medicamentos.`)
          setIsLoading(false)
        })

      analytics.logEvent('click_imprimir_modal_reimpressao_prescricao')
    }

    return (
      <Fragment>
        <div
          css={css`
            margin-top: -1.5rem;
            padding: 0 2.25rem;
          `}
        >
          <Box style={styles.checkBox}>
            <Checkbox
              label='Marcar todos'
              checked={allChecked}
              onChange={handleOnChangeAllChecked}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </Box>
          <EncaminharPrescricaoMedicamentoItem
            prescricaoDigital={prescricaoDigital}
            medicamentoIdsSelecionado={medicamentosSelected.map((med) => med.id)}
            medicamentosToPrint={medicamentosToPrint}
            onChange={handleOnChangeMedicamentosSelected}
          />
          {(hasTalidomida || hasIsotretinoinaTretinoinaAcitretina(medicamentosToPrint)) && (
            <Fragment>
              <Text
                css={css`
                  margin-bottom: 0.5rem;
                `}
              >
                Selecione os termos que deseja imprimir
              </Text>

              <TermosToPrintList
                cidadaoIdade={idadeCidadao}
                cidadaoSexo={cidadaoSexo}
                talidomidas={talidomidas}
                termos={termosSelected.map((term) => term.termo)}
                termosToPrint={termosToPrint.current}
                hasAcitretinaIsotretinoinaTretinoina={hasIsotretinoinaTretinoinaAcitretina(medicamentosToPrint)}
                acitretinaIsotretinoinaTretinoinaSub={subtitleTermos}
                onClickCheckbox={handleOnChangeTermosSelected}
                findAlreadyPrintedElement={findAlreadyPrintedElement}
                isReimpressao={isReimpressao}
              />
            </Fragment>
          )}
        </div>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={onModalClose} style={styles.footerButton} data-cy='CancelarImprimirButton'>
              Cancelar
            </Button>

            <Tooltip text={noSelections && 'Selecione um medicamento para possibilitar a impressão'}>
              <Button
                kind='primary'
                onClick={isReimpressao ? handlePrintReimpressao : handlePrintImpressao}
                style={styles.footerButton}
                disabled={noSelections}
                loading={isLoading}
                data-cy='ImprimirPrescricaoButton'
              >
                Imprimir
              </Button>
            </Tooltip>
          </HFlow>
        </ModalFooter>
      </Fragment>
    )
  }

  return <FieldArray<ID> name={resolveName(path.alreadyPrinted)} render={renderFieldArray} />
}

const styles = {
  footerButton: css`
    width: 10rem;
    height: 3rem;
  `,
  checkBox: css`
    border: 1px solid;
    border-radius: 2px;
    border-color: ${theme.pallete.gray.c80};
    padding: 0.5rem 0.75rem;
  `,
}
