import { CiapCidSelectModel, isCiap } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { AcompanhamentoCondicaoSaudeQueryInput, FaixaEtariaEnum, FaixaEtariaInput } from 'graphql/types.generated'
import { isNumber, partition } from 'lodash'
import { idGrupoCondicao } from 'types/enums'

import { convertFaixaEtariaAcompanhamentoInput } from '../converter/converter-faixaEtariaAcompanhamento'
import { AcompanhamentoCondicaoSaudeFormModel, faixaEtariaRecord } from './model-acompanhamentoCondicaoSaude'

export default (model: AcompanhamentoCondicaoSaudeFormModel): AcompanhamentoCondicaoSaudeQueryInput => {
  const [ciaps, cids] = partition(model?.problemasCondicoes, (item) => isCiap(item))
  return {
    ciaps: ciaps.map((item: CiapCidSelectModel) => item.codigo),
    cid10: cids.map((item: CiapCidSelectModel) => item.codigo),
    grupoCondicao: model?.grupoCondicao?.map((item) => idGrupoCondicao[item.grupoCondicao]),
    somenteProblemasCondicoesAtivas: model?.ativoListaProblema ? model.ativoListaProblema : false,
    unidadeResponsavelId: model?.unidadeResponsavelId,
    equipeResponsavelId: model.isCoordenador ? model?.equipeResponsavel?.id : model?.equipeResponsavelId,
    sexo: model?.sexo,
    identidadeGenero: model?.identidadeGenero,
    faixaEtaria: convertFaixaEtariaAcompanhamentoInput(model?.tipoFaixaEtaria, mountFaixaEtaria(model)),
    periodoUltimoAtendimento: model?.periodoUltimoAtendimento,
    microareas: model?.microareas,
    ivcfRiscoVulnerabilidade: model?.ivcfRiscoVulnerabilidade,
    ivcfSubgrupo: model?.ivcfSubgrupo,
    ivcfPontuacaoMin: model?.ivcfPontuacao?.firstValue,
    ivcfPontuacaoMax: model?.ivcfPontuacao?.secondValue,
    exibirCidadaoIdosoSemIvcf: model?.exibirCidadaoIdosoSemIvcf,
    isBotaoIvcfAtivo: model?.isBotaoIvcfAtivo,
    pageParams: {
      sort: ['cidadao', 'endereco', 'dataAtendimento'],
      ...model.pageParams,
    },
  }
}

function mountFaixaEtaria(model: AcompanhamentoCondicaoSaudeFormModel): FaixaEtariaInput {
  const { tipoFaixaEtaria, faixaEtariaRange } = model

  return tipoFaixaEtaria !== FaixaEtariaEnum.OUTRA
    ? faixaEtariaRecord[tipoFaixaEtaria]
    : {
        idadeMinima: faixaEtariaRange?.firstValue,
        idadeMaxima: faixaEtariaRange?.secondValue,
        tipoFaixaEtaria:
          isNumber(faixaEtariaRange?.firstValue) || isNumber(faixaEtariaRange?.secondValue)
            ? faixaEtariaRange?.tipoFaixaEtaria
            : null,
      }
}
