import { useAcompanhamentoVulnerabilidadeCardQuery } from 'graphql/hooks.generated'
import React, { useEffect, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { calculateAge } from 'util/date/calculateAge'

import { ProntuarioCard } from '../ProntuarioCard'
import { AcompanhamentoVulnerabilidadeCard } from './AcompanhamentoVulnerabilidadeCard'

interface AcompanhamentoVulnerabilidadeSectionProps {
  cidadaoId: ID
  dataReferencia: Instant
}

export function AcompanhamentoVulnerabilidadeSection(props: AcompanhamentoVulnerabilidadeSectionProps) {
  const { cidadaoId, dataReferencia } = props

  const [showAcompanhamentoVulnerabilidade, setShowAcompanhamentoVulnerabilidade] = useState<boolean>(false)

  const {
    data: { cidadao },
    loading,
  } = useAcompanhamentoVulnerabilidadeCardQuery({
    variables: { cidadaoId },
    context: { newRequest: true },
  })

  const isCidadaoVulneravel = isCidadaoVulneravelAlimentar(cidadao, dataReferencia)
  const familiaresUnicos = Array.from(new Set(cidadao?.familias.flatMap((familia) => familia.cidadaos) ?? []))
  const familiarVulneravel = familiaresUnicos.find((familiar) => isCidadaoVulneravelAlimentar(familiar, dataReferencia))

  const isBeneficiarioBolsaFamilia = cidadao?.beneficiarioBolsaFamilia
  const isVulneravelAlimentar = isCidadaoVulneravel || !isUndefinedOrNull(familiarVulneravel)
  const dataRegistro = isCidadaoVulneravel
    ? cidadao.informacoesSocioEconomicasDW.dataRegistro
    : familiarVulneravel && familiarVulneravel.informacoesSocioEconomicasDW.dataRegistro

  useEffect(() => {
    setShowAcompanhamentoVulnerabilidade(!loading && (isBeneficiarioBolsaFamilia || isVulneravelAlimentar))
  }, [isBeneficiarioBolsaFamilia, isVulneravelAlimentar, loading])

  return showAcompanhamentoVulnerabilidade ? (
    <ProntuarioCard title='Acompanhamento de vulnerabilidade'>
      <AcompanhamentoVulnerabilidadeCard
        dataRegistro={dataRegistro}
        isBeneficiarioBolsaFamilia={isBeneficiarioBolsaFamilia}
      />
    </ProntuarioCard>
  ) : null
}

const isCidadaoVulneravelAlimentar = (cidadao, dataReferencia: Instant): boolean => {
  const isCidadaoMaioridade = isCidadaoOlderThan18Years(cidadao, dataReferencia)
  const isCidadaoVulneravel =
    cidadao?.informacoesSocioEconomicasDW &&
    cidadao.informacoesSocioEconomicasDW.alimentosAcabaramSemDinheiro &&
    cidadao.informacoesSocioEconomicasDW.comeuPoucoPorFaltaDeDinheiro

  return isCidadaoMaioridade && isCidadaoVulneravel
}

const isCidadaoOlderThan18Years = (cidadao, dataReferencia: Instant): boolean => {
  const idadeEmAnos: number = cidadao?.dataNascimento && calculateAge(cidadao.dataNascimento, dataReferencia).years
  return idadeEmAnos >= 18
}
