import { blue, gray, orange, pink, purple, turquoise } from 'bold-ui/lib/styles/colors'
import {
  BairroSelectFieldModel,
  EquipeSelectModel,
  SimNaoEnum,
  TipoLogradouroSelectModel,
  UnidadeSaudeSelectModel,
} from 'components/form'
import { AldeiaSelectFieldModel } from 'components/form/field/select/AldeiaSelectField'
import { DseiSelectFieldModel } from 'components/form/field/select/DseiSelectField'
import { PeriodoUltimaAtualizacaoSelectModel } from 'components/form/field/select/PeriodoUltimaAtualizacaoSelectField'
import { PeriodoUltimaVisitaSelectModel } from 'components/form/field/select/PeriodoUltimaVisitaSelectField'
import { PoloBaseSelectFieldModel } from 'components/form/field/select/PoloBaseSelectField'
import { StatusCadastroSelectModel } from 'components/form/field/select/StatusCadastroSelectField'
import { VisualizarCidadaosImovelQueryResult } from 'graphql/hooks.generated'
import {
  AnimaisDomicilioEnum,
  AnimaisImovelInput,
  CondicoesMoradiaInput,
  LogradouroTerritorioDto,
  PageParams,
  TerritorioMicroareaQuery,
  TipoDomicilioEnum,
  TipoEnderecoEnum,
  TipoImovelEnum,
  TipoLocalizacaoEnum,
  TipoLogradouro,
  Uf,
  VisualizarCidadaosImovelQuery,
} from 'graphql/types.generated'
import React from 'react'
import { AnimaisImovelFormModel, CondicoesMoradiaFormModel } from 'view/cadastro-imovel/model-cadastroImovel'
import { FamiliasTerritorio } from 'view/reterritorializacao/model'

import { ReactComponent as AbrigoHeaderIcon } from '../../images/visualizacao-imovel/abrigo.svg'
import { ReactComponent as ComercioHeaderIcon } from '../../images/visualizacao-imovel/comercio.svg'
import { ReactComponent as CrecheHeaderIcon } from '../../images/visualizacao-imovel/creche.svg'
import { ReactComponent as DelegaciaHeaderIcon } from '../../images/visualizacao-imovel/delegacia.svg'
import { ReactComponent as DomicilioHeaderIcon } from '../../images/visualizacao-imovel/domicilio.svg'
import { ReactComponent as EstabelecimentoReligiosoHeaderIcon } from '../../images/visualizacao-imovel/estabelecimento-religioso.svg'
import { ReactComponent as InstituicaoLongaPermanenciaHeaderIcon } from '../../images/visualizacao-imovel/inst-longa-permanencia-idosos.svg'
import { ReactComponent as OutrosImoveisHeaderIcon } from '../../images/visualizacao-imovel/outros-imoveis-casa.svg'
import { ReactComponent as PontoEstrategicoHeaderIcon } from '../../images/visualizacao-imovel/ponto-estratégico.svg'
import { ReactComponent as ApartamentoHeaderIcon } from '../../images/visualizacao-imovel/predio.svg'
import { ReactComponent as TerrenoBaldioHeaderIcon } from '../../images/visualizacao-imovel/terreno-baldio.svg'
import { ReactComponent as UnidadePrisionaHeaderIcon } from '../../images/visualizacao-imovel/unidade-prisional.svg'
import { ReactComponent as UnidadeSocioEducativaHeaderIcon } from '../../images/visualizacao-imovel/unidade-socioeducativa.svg'
import { ReactComponent as UniversidadeHeaderIcon } from '../../images/visualizacao-imovel/universidade.svg'
import { ReactComponent as AbrigoIcon } from '../../images/visualizacao-territorial/abrigo-icone.svg'
import { ReactComponent as ApartamentoIcon } from '../../images/visualizacao-territorial/apartamento-icone.svg'
import {
  ReactComponent as CasaIcon,
  ReactComponent as DomicilioIcon,
} from '../../images/visualizacao-territorial/casa-icone.svg'
import { ReactComponent as ComercioIcon } from '../../images/visualizacao-territorial/comercio-icone.svg'
import { ReactComponent as CrecheIcon } from '../../images/visualizacao-territorial/creche-icone.svg'
import { ReactComponent as DelegaciaIcon } from '../../images/visualizacao-territorial/delegacia-icone.svg'
import { ReactComponent as EscolaIcon } from '../../images/visualizacao-territorial/escola-icone.svg'
import { ReactComponent as EstabelecimentoReligiosoIcon } from '../../images/visualizacao-territorial/estabelecimento-religioso-icone.svg'
import { ReactComponent as InstituicaoLongaPermanenciaIcon } from '../../images/visualizacao-territorial/longa-permanencia-idosos-icone.svg'
import { ReactComponent as OutrosImoveisIcon } from '../../images/visualizacao-territorial/outros-imoveis.svg'
import { ReactComponent as PontoEstrategicoIcon } from '../../images/visualizacao-territorial/ponto-estrategico-icone.svg'
import { ReactComponent as TerrenoBaldioIcon } from '../../images/visualizacao-territorial/terreno-baldio-icone.svg'
import { ReactComponent as UnidadePrisionalIcon } from '../../images/visualizacao-territorial/unidade-prisional-icone.svg'
import { ReactComponent as UnidadeSocioEducativaIcon } from '../../images/visualizacao-territorial/unidade-socioeducativa-icone.svg'

export interface VisualizacaoTerritorialBuscaFormModel {
  unidadeSaude: UnidadeSaudeSelectModel
  equipe: EquipeSelectModel
  bairro?: string
  cep?: string
  tipoLogradouro?: TipoLogradouroSelectModel
  logradouro?: string
  tipoEndereco?: TipoEnderecoEnum
  dsei?: DseiSelectFieldModel
  poloBase?: PoloBaseSelectFieldModel
  aldeia?: AldeiaSelectFieldModel
}
export interface VisualizacaoTerritorialCacheModel {
  visualizacaoFilter: VisualizacaoFilterModel
  currentMicroareaTab: string
  expandedItems: ID[]
  pageParams: PageParams
  isRedirect: boolean
}
export interface VisualizacaoMicroareasFilterFormModel {
  isCadastroAtualizadoMenosDeUmAno?: PeriodoUltimaAtualizacaoSelectModel
  isCadastroCompleto?: StatusCadastroSelectModel
  isImovelVisitadoMenosDeUmMes?: PeriodoUltimaVisitaSelectModel
  condicoesMoradia?: CondicoesMoradiaFormModel
  animaisImovelFilter?: AnimaisImovelFilterModel
  tipoImovel?: TipoImovelEnum
}

export interface VisualizacaoMicroareasFilterModel {
  isCadastroAtualizadoMenosDeUmAno?: boolean
  isCadastroCompleto?: boolean
  isImovelVisitadoMenosDeUmMes?: boolean
  condicoesMoradia?: CondicoesMoradiaFormModel
  animaisImovel?: AnimaisImovelFormModel
  tipoImovel?: TipoImovelEnum
}

export interface AnimaisImovelFilterModel {
  possuiAnimais?: SimNaoEnum
  animaisNoImovel?: AnimaisDomicilioEnum[]
}

export interface VisualizacaoFilterModel {
  territorioEquipeFilter: VisualizacaoTerritorialBuscaFormModel
  territorioMicroareasFilter?: VisualizacaoMicroareasFilterModel
}

export type TerritorioMicroareaQueryResult = TerritorioMicroareaQuery['territorioMicroarea']
export type TerritorioMicroareaItemModel = TerritorioMicroareaQueryResult['logradouros']['content'][0]

export interface TipoImovelListModel {
  ImovelIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  fill: string
  tooltip: string
  tipoResponsavel: TipoResponsavelEnum
  HeaderIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export interface TerritorioModel {
  cnes: string
  microarea: string
  ine: string
  cep?: string
  isCadastroAtualizadoMenosDeUmAno?: boolean
  isCadastroCompleto?: boolean
  isImovelVisitadoMenosDeUmMes?: boolean
}

export type FamiliaVisualizacaoImovel = VisualizarCidadaosImovelQuery['imovel']['familias'][number]

export type CidadaoVisualizacaoImovel = VisualizarCidadaosImovelQuery['imovel']['familias'][number]['cidadaosTerritorioBasico'][number]

export interface VisualizacaoMicroareaTerritorioInput
  extends Omit<VisualizacaoMicroareasFilterModel, 'animaisImovel' | 'condicoesMoradia'> {
  animaisImovel?: AnimaisImovelInput
  condicoesMoradia?: CondicoesMoradiaInput
}

export const tipoLocalizacaoRecord: Record<TipoLocalizacaoEnum, string> = {
  [TipoLocalizacaoEnum.URBANA]: 'Urbana',
  [TipoLocalizacaoEnum.RURAL]: 'Rural',
  [TipoLocalizacaoEnum.PERIURBANA]: 'Periurbana',
}

export type TipoDomicilioTag = 'Casa' | 'Apartamento' | 'Cômodo' | 'Outro' | 'Maloca' | 'Improvisado'

export type TipoLocalizacaoTag = 'Urbana' | 'Rural' | 'Periurbana'

export interface ImovelModel {
  imovelId: ID
  uuidDomicilio: ID
  tipoImovel?: TipoImovelEnum
  ultimaFichaCadastrada?: {
    id: ID
    isProcessado: boolean
  }
  numeroMoradores?: number
  condicoesMoradia: {
    tipoDomicilio?: TipoDomicilioEnum
  }
  cep: string
  complemento?: string
  nomeResponsavelTecnico?: string
  numero: string
  familias: FamiliasTerritorio[]
  dataUltimaVisita: number
  logradouro: string
  bairro: string
  isTipoEnderecoIndigena: boolean
  tipoDomicilio: string
  materialPredominante: string
  numeroResidenciaIndigena: string
  nomeTipoLogradouro: string
  dataUltimaAtualizacao?: number
  isCadastroCompleto?: boolean
  isCadastroRecusado?: boolean
}

export enum TipoResponsavelEnum {
  FAMILIAR = 1,
  TECNICO = 2,
  SEM_RESPONSAVEL = 3,
}

export enum StatusFichaEnum {
  NAO_ENVIADA = 'NAO_ENVIADA',
  ENVIADA = 'ENVIADA',
  RASCUNHO = 'RASCUNHO',
}

export enum TipoInconsistenciaEnum {
  RESPONSAVEL_SEM_CADASTRO_INDIVIDUAL = 1,
  RESPONSAVEL_NAO_DECLARADO = 2,
  RESPONSAVEL_OUTRO_DOMICILIO = 3,
  RESPONSAVEL_MUDOUSE = 4,
  RESPONSAVEL_OBITO = 5,
}

export const tipoImovelRecord: Record<TipoImovelEnum, TipoImovelListModel> = {
  [TipoImovelEnum.DOMICILIO]: {
    ImovelIcon: DomicilioIcon,
    fill: blue.c60,
    tooltip: 'Domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
    HeaderIcon: DomicilioHeaderIcon,
  },
  [TipoImovelEnum.COMERCIO]: {
    ImovelIcon: ComercioIcon,
    fill: orange.c60,
    tooltip: 'Comércio',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
    HeaderIcon: ComercioHeaderIcon,
  },
  [TipoImovelEnum.TERRENO_BALDIO]: {
    ImovelIcon: TerrenoBaldioIcon,
    fill: purple.c40,
    tooltip: 'Terreno baldio',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
    HeaderIcon: TerrenoBaldioHeaderIcon,
  },
  [TipoImovelEnum.PONTO_ESTRATEGICO]: {
    ImovelIcon: PontoEstrategicoIcon,
    fill: orange.c60,
    tooltip: 'Ponto estratégico',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
    HeaderIcon: PontoEstrategicoHeaderIcon,
  },
  [TipoImovelEnum.ESCOLA]: {
    ImovelIcon: EscolaIcon,
    fill: turquoise.c60,
    tooltip: 'Escola',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
    HeaderIcon: UniversidadeHeaderIcon,
  },
  [TipoImovelEnum.CRECHE]: {
    ImovelIcon: CrecheIcon,
    fill: turquoise.c60,
    tooltip: 'Creche',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
    HeaderIcon: CrecheHeaderIcon,
  },
  [TipoImovelEnum.ABRIGO]: {
    ImovelIcon: AbrigoIcon,
    fill: pink.c40,
    tooltip: 'Abrigo',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
    HeaderIcon: AbrigoHeaderIcon,
  },
  [TipoImovelEnum.INSTITUICAO_LONGA_PERMANENCIA_PARA_IDOSOS]: {
    ImovelIcon: InstituicaoLongaPermanenciaIcon,
    fill: pink.c40,
    tooltip: 'Inst. Longa permanência para idosos',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
    HeaderIcon: InstituicaoLongaPermanenciaHeaderIcon,
  },
  [TipoImovelEnum.UNIDADE_PRISIONAL]: {
    ImovelIcon: UnidadePrisionalIcon,
    fill: blue.c20,
    tooltip: 'Unidade prisional',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
    HeaderIcon: UnidadePrisionaHeaderIcon,
  },
  [TipoImovelEnum.UNIDADE_MEDIDA_SOCIOEDUCATIVA]: {
    ImovelIcon: UnidadeSocioEducativaIcon,
    fill: blue.c20,
    tooltip: 'Unidade socioeducativa',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
    HeaderIcon: UnidadeSocioEducativaHeaderIcon,
  },
  [TipoImovelEnum.DELEGACIA]: {
    ImovelIcon: DelegaciaIcon,
    fill: blue.c20,
    tooltip: 'Delegacia',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
    HeaderIcon: DelegaciaHeaderIcon,
  },
  [TipoImovelEnum.ESTABELECIMENTO_RELIGIOSO]: {
    ImovelIcon: EstabelecimentoReligiosoIcon,
    fill: orange.c60,
    tooltip: 'Estabelecimento religioso',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
    HeaderIcon: EstabelecimentoReligiosoHeaderIcon,
  },
  [TipoImovelEnum.CASAI]: {
    ImovelIcon: OutrosImoveisIcon,
    fill: gray.c20,
    tooltip: 'Casai',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
    HeaderIcon: OutrosImoveisHeaderIcon,
  },
  [TipoImovelEnum.OUTROS]: {
    ImovelIcon: OutrosImoveisIcon,
    fill: gray.c20,
    tooltip: 'Outros imóveis',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
    HeaderIcon: OutrosImoveisHeaderIcon,
  },
}
export const domicilioTipoImovelRecord: Record<TipoDomicilioEnum, TipoImovelListModel> = {
  [TipoDomicilioEnum.APARTAMENTO]: {
    ImovelIcon: ApartamentoIcon,
    fill: blue.c60,
    tooltip: 'Apartamento',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
    HeaderIcon: ApartamentoHeaderIcon,
  },
  [TipoDomicilioEnum.CASA]: {
    ImovelIcon: CasaIcon,
    fill: blue.c60,
    tooltip: 'Casa/domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
    HeaderIcon: DomicilioHeaderIcon,
  },
  [TipoDomicilioEnum.COMODO]: {
    ImovelIcon: CasaIcon,
    fill: blue.c60,
    tooltip: 'Casa/domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
    HeaderIcon: DomicilioHeaderIcon,
  },
  [TipoDomicilioEnum.IMPROVISADO]: {
    ImovelIcon: CasaIcon,
    fill: blue.c60,
    tooltip: 'Casa/domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
    HeaderIcon: DomicilioHeaderIcon,
  },
  [TipoDomicilioEnum.MALOCA]: {
    ImovelIcon: CasaIcon,
    fill: blue.c60,
    tooltip: 'Casa/domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
    HeaderIcon: DomicilioHeaderIcon,
  },
  [TipoDomicilioEnum.OUTRO]: {
    ImovelIcon: CasaIcon,
    fill: blue.c60,
    tooltip: 'Casa/domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
    HeaderIcon: DomicilioHeaderIcon,
  },
}

export const domicilioTipoRecord: Record<TipoDomicilioEnum, TipoDomicilioTag> = {
  [TipoDomicilioEnum.CASA]: 'Casa',
  [TipoDomicilioEnum.APARTAMENTO]: 'Apartamento',
  [TipoDomicilioEnum.COMODO]: 'Cômodo',
  [TipoDomicilioEnum.MALOCA]: 'Maloca',
  [TipoDomicilioEnum.IMPROVISADO]: 'Improvisado',
  [TipoDomicilioEnum.OUTRO]: 'Outro',
}

export const localizacaoTipoRecord: Record<TipoLocalizacaoEnum, TipoLocalizacaoTag> = {
  [TipoLocalizacaoEnum.URBANA]: 'Urbana',
  [TipoLocalizacaoEnum.RURAL]: 'Rural',
  [TipoLocalizacaoEnum.PERIURBANA]: 'Periurbana',
}

export const IMOVEIS_COM_RESPONSAVEL_TECNICO: string[] = [
  TipoImovelEnum.ABRIGO,
  TipoImovelEnum.INSTITUICAO_LONGA_PERMANENCIA_PARA_IDOSOS,
  TipoImovelEnum.UNIDADE_PRISIONAL,
  TipoImovelEnum.UNIDADE_MEDIDA_SOCIOEDUCATIVA,
  TipoImovelEnum.DELEGACIA,
]

export type EditarAtualizarImovelCallback = (
  isUltimaFichaCadastradaProcessada: boolean,
  uuidOrigemImovel: ID,
  ultimaFichaCadastradaId: ID,
  imovelId: ID
) => void

export interface LogradouroEditFormModel {
  id: ID
  tipoLogradouro?: TipoLogradouro
  logradouro: string
  bairro: BairroSelectFieldModel
  cep: string
  tipoEndereco: TipoEnderecoEnum
  dsei: DseiSelectFieldModel
  poloBase: PoloBaseSelectFieldModel
  aldeia: AldeiaSelectFieldModel
}

export type EditarAtualizarCidadaoCallback = (
  isUltimaFichaCadastradaProcessada: boolean,
  uuidCidadao: ID,
  ultimaFichaCadastradaId: ID,
  imovelId: ID
) => void

export interface InconsistenciaImovelModel {
  titulo: string
  sugestao: string
}

export const inconsistenciasRecord: Record<TipoInconsistenciaEnum, InconsistenciaImovelModel> = {
  [TipoInconsistenciaEnum.RESPONSAVEL_SEM_CADASTRO_INDIVIDUAL]: {
    titulo: 'Responsável sem cadastro individual no território',
    sugestao:
      'A família é reativada assim que uma ficha de cadastro individual com o CPF ou CNS do responsável for cadastrada.',
  },
  [TipoInconsistenciaEnum.RESPONSAVEL_NAO_DECLARADO]: {
    titulo: 'Responsável não declarado no cadastro individual',
    sugestao:
      'A família é reativada assim que a ficha de cadastro individual do responsável passar a registrar "Sim" no campo de "Responsável familiar".',
  },
  [TipoInconsistenciaEnum.RESPONSAVEL_OUTRO_DOMICILIO]: {
    titulo: 'Responsável em outro domicílio',
    sugestao:
      'Será necessário atualizar os domicílios, marcando a opção "Mudou-se" no grupo "Famílias", até que o responsável esteja cadastrado em apenas um domicílio.',
  },
  [TipoInconsistenciaEnum.RESPONSAVEL_MUDOUSE]: {
    titulo: 'Responsável com mudança de território',
    sugestao:
      'É necessário informar o "CPF / CNS do responsável familiar" cujo cadastro individual não possua a informação da mudança de território.',
  },
  [TipoInconsistenciaEnum.RESPONSAVEL_OBITO]: {
    titulo: 'Responsável com óbito no cadastro individual',
    sugestao:
      'É necessário informar o "CPF / CNS do responsável familiar" cujo cadastro individual não possua a informação do óbito.',
  },
}

export type FamiliaInconsistenciaModel = VisualizarCidadaosImovelQueryResult['data']['imovel']['familias'][0]

export interface AccordionItemHeaderProps {
  item: TerritorioMicroareaItemModel
  canCboWriteFicha: boolean
  processingLogradouroId: ID
  progressToken: string
  onCompleteProcessing(): void
  onEdit(): void
}

export interface LogradouroTerritorio extends Omit<LogradouroTerritorioDto, 'municipio'> {
  municipio: {
    id: ID
    ibge?: string
    nome: string
    uf: Uf
  }
}

export const ACOMPANHAMENTO_TERRITORIO_MICROAREA_FILENAME = 'acompanhamento-territorio-microarea'
export const RELATORIO_ACOMPANHAMENTO_TERRITORIO_ATMOSPHERE_PATH = 'gerarCsvTerritorio'
